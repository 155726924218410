import { FaUsers } from "react-icons/fa";
import { LuArrowLeftRight, LuBarChart2, LuBoxes, LuDollarSign, LuFileText, LuLink, LuLink2, LuListChecks, LuLocate, LuMapPin, LuPercent, LuStore, LuUploadCloud, LuUser2, LuUserCog2, LuUserPlus, LuUsers, LuUsers2, LuWrench } from "react-icons/lu";
import { PageAdminLogin } from "../../pages/admin/auth/PageLogin";   
import { fase } from "../../components/session/SessionAdmin";  
import {PageAdminDashboard} from "../../pages/admin/dashboard/PageAdminDashboard";
import {PageAdminGatewayDocument} from "../../pages/admin/document/PageAdminGatewayDocument";
import {PageAdminGateway} from "../../pages/admin/gateway/PageAdminGateway";
import { GiBank } from "react-icons/gi";

export function getPainelRoutes(){
    return painelRoutes;
};
let painelRoutes = null;
export function getMenuRoutes(login=false,acessos={}){
    let sendM= [];
    let subs = {}; 
    painelRoutes.forEach(rota => {
        if(login ){   
            if(rota.login  && (rota.fase == null|| rota.fase == fase)){
                if(rota.sub == null){
                    sendM.push({title:rota.title,icon:rota.icon,link:rota.url,hidden:rota.menu,tema:rota.tema});
                }else{
                    if(typeof subs[rota.sub.title] == 'undefined'){
                        subs[rota.sub.title] = {title:rota.sub.title,icon:rota.sub.icon,sub:[],hidden:false};
                        painelRoutes.forEach(rotaS => {
                            if(rotaS.sub!==null && rota.sub.title == rotaS.sub.title){
                                if(rotaS.menu){
                                    subs[rota.sub.title].hidden=true;
                                }
                                subs[rota.sub.title].sub.push({title:rotaS.title,icon:rotaS.icon,link:rotaS.url,hidden:rotaS.menu,tema:rotaS.tema})
                            }
                        })
                        sendM.push(subs[rota.sub.title]);
                    } 
                }
                
            }
        }else{
            if(!rota.login && rota.menu){
                sendM.push(rota);
            }
        }
    });
    return sendM;
};
const newRoutePainel = function({title='',icon=null,description='',url = '',tema=false,layout='painel',login=false,page, session='sessionPainel',redirect='',menu=false, sub=null,acesso=0,fase:faseI=null}){
    painelRoutes.push({
        title,
        icon,
        description,
        layout,
        login,
        page,
        url,
        session,
        redirect,
        menu,
        sub,
        fase:faseI,
        tema,
        acesso
    })
}

const controller = '/admin';
export function loadPainelRoutes(){
    if(painelRoutes == null){
        painelRoutes = [];
        
        newRoutePainel({ title       :   "Login",       url:controller+"",             page        : PageAdminLogin,     tema:false,   login       : false, redirect    : controller+'/dashboard' });
        newRoutePainel({ title       :   "Login",       url:controller+"/login",       page        : PageAdminLogin,     tema:false,    login       : false, redirect    : controller+'/dashboard' });
       
        newRoutePainel({ title       :   "Início",         icon:LuBarChart2,       url:controller+"/dashboard",    page    : PageAdminDashboard,   tema:true,  login : true , menu:true  });
        
         
        // newRoutePainel({ title       :   "Todas Pedidos",       url:controller+"/reports",         page:   PageAdminReports,         tema:true,    login : true , menu:true  ,     sub:{ title       :   "Transações",  icon: MdOutlineBusinessCenter}});
        // newRoutePainel({ title       :   "Saques Pendentes",    url:controller+"/withdraw",        page:   PageAdminWithdraw,        tema:true,    login : true , menu:true  ,     sub:{ title       :   "Transações"}});
        // newRoutePainel({ title       :   "Todas Transações",    url:controller+"/transaction",     page:   PageAdminTransaction,        tema:true,    login : true , menu:true  ,     sub:{ title       :   "Transações"}});
        
 
        newRoutePainel({ title       :   "Aprovar Documentos",  url:controller+"/gateway/approve",   page:   PageAdminGatewayDocument,        tema:true,    login : true , menu:true  ,     sub:{ title :   "Gateways",  icon: GiBank }});
        newRoutePainel({ title       :   "Listar Gateways",     url:controller+"/gateway/list",      page:   PageAdminGateway,        tema:true,    login : true , menu:true  ,         sub:{ title :   "Gateways"}});
      
        // newRoutePainel({ title       :   "Adquirentes", icon: TbPlugConnected  , url:controller+"/integration/acquirer",        page:   PageAdminAcquirer,        tema:true,    login : true , menu:true  });
        //  newRoutePainel({ title       :   "Planos",   icon:  LuPercent ,   url:controller+"/plans/list",        page:   PageAdminPlans,        tema:true,    login : true , menu:true  });
       
        
        // newRoutePainel({ title       :   "Por Loja",        url:controller+"/scale/store",        page:   PageAdminScaleStore,        tema:true,    login : true , menu:true  ,    sub:{ title :   "Escalabilidade",  icon: LuBarChart2}});
        // newRoutePainel({ title       :   "Por Cliente",     url:controller+"/scale/client",        page:   PageAdminScaleClient,        tema:true,    login : true , menu:false  ,                  sub:{ title :   "Escalabilidade"}});
       
        
        // newRoutePainel({ title       :   "Logs",            icon:LuFileText ,       url:controller+"/logs",    page    : PageAdminLog,   tema:true,  login : true , menu:false  });
 
    } 
    return painelRoutes;
}