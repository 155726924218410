import { Flex, HStack, SimpleGrid, Stack, Text, Textarea, useToast  } from "@chakra-ui/react";
import { HK4, HK5, PageHK, SubTHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendFormAdmin"; 
import { useEffect, useRef, useState } from "react"; 
import { sendFormSocket } from "../../../components/session/SessionAdmin";
import { LojaSelect } from "../../../layout/painel/MenuPainel";  
import { gerarPassword } from "../../../components/Function";
import { InputKCopy, TextareaKCopy } from "../../../components/input/InputKalystCopy";
import { config } from "../../../theme/PageConfig";

export default function FormRegistroCliente( {open,setOpen,edit = null,setEdit,prods=[],frets=[],pixes=[]}){  
    const [load,setLoadD] = useState(null);
    const [base,setBase] = useState(null);
    const [reload,setReload] = useState(false);
    const toast = useToast()
    const [produtos,setProdutos] = useState([]);
    const [frete,setFrete] = useState([]);
    const [pixel,setPixel] = useState([]);
    const [desc,setDesc] = useState('');
    const [initial,setInitial]    = useState(false);
    const [senha,setSenha]    = useState(null);
    const [info,setInfo]    = useState(null); 
    
    
    const [addProd,setAddProd] = useState({ pro: {}, uni: 0 });
    
    useEffect(()=>{
        if(edit!==null){
            // setLoadD(0);
            // setTimeout(()=>{
            //     sendFormSocket({ modulo:'link',cmd:'info',form:{id:edit},callback:(result)=>{ 
            //         setLoadD(1);
            //         setBase(result);
            //         setReload(true);
                   
            //     }});
            // },1000);
        }else{
            setLoadD(1);
        }  
    },[])   
    let dados = {}; 
    let sendD ={
        loja:LojaSelect,
        produtos:produtos,
        frete:frete,
        pixel:pixel
    }; 
    if(senha == null){ 
        setSenha(gerarPassword()) 
        setReload(true);
    }    
    let listPord = [];
    prods.forEach((v)=>{ 
        var add = true;
        produtos.forEach((s)=>{  
            if(v.value ==s.id){  
                add =false;
            } 
        })
        if(add){  
            listPord.push(v);
        } 
    })
    let listFrets = [];
    frets.forEach((v)=>{ 
        var add = true;
        frete.forEach((s)=>{  
            if(v.value ==s.id){  
                add =false;
            } 
        })
        if(add){  
            listFrets.push(v);
        } 
    }) 
    let listPixel = [];
    pixes.forEach((v)=>{ 
        var add = true;
        frete.forEach((s)=>{  
            if(v.value ==s.id){  
                add =false;
            } 
        })
        if(add){  
            listPixel.push(v);
        } 
    })
    var total = 0;

    if(!reload){
        console.log(`*Link Server API:* `+(config.web.replace('subadquirente.','sub.'))+`  *Painel Adquirente:* https://`+config.web)
        if(info !== null){
const copy = 
`*Link Server API:* `+(config.web.replace('subadquirente.','sub.'))+` 
*Chave Pública:*  `+info.pub+`
*Chave Privada:*  `+info.sec+`
 
*Painel Adquirente:* https://`+config.web+`
*E-mail:* `+info.email+`
*Senha:* `+info.senha1;
             console.log(info);
             return <Stack w='full'  >
                 <TextareaKCopy    loaded={load} focus={false}   label="Dados de acesso" initial={{copy}}   name="copy"   autoComplete={false}/>   
                  <Flex   w={'100%'} align={'center'} justify={'flex-end'} gap={'16px'}>  
                        <ButtonKalyst variant={'admin'}   onClick={()=>{
                            setInfo(null); 
                        }} >Cadastrar Outro</ButtonKalyst>
                        <ButtonKalyst variant={'ghost'}  colorScheme="red"  border='1px solid' onClick={()=>{
                            setOpen(false);
                        }} >Fechar</ButtonKalyst>
                    </Flex> 
             </Stack>   
        }else{
            return (
                <SendForm  dados = {{modulo:'user',cmd:'register'}}  
                schema={ 
                    object({ 
                        nome:       string().required('Razão Social é obrigatório'),
                        fantasia:   string().required('Nome Fantasia é obrigatório'),
                        email:      string().required('E-mail é obrigatório'), 
                        cnpj:       string().required('CNPJ é obrigatório'), 
                        telefone:   string().required('Telefone é obrigatório'),
                        senha1:     string().required('Senha é obrigatória')
                    })
                } 
                callback={(result)=>{   
                    setInfo(result.hash);  
                }}
                gap={'10px'}
                > 
                <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Dados da Empresa</HK4>   
                <InputK  type='text' label="Razão Social"   name="nome" placeholder="Razão Social"   focus={true} autoComplete={false}/>
                <Flex w={'full'} gap='20px'>
                    <InputK  type='text' label="Fantasia"   name="fantasia" placeholder="Nome Fantasia"   focus={false} autoComplete={false}/>
                    <InputK  type='cnpj' label="CNPJ"   name="cnpj" placeholder="00.000.000/0000-00"   autoComplete={false}/>
                </Flex>
                <Flex w={'full'} gap='20px'>
                   
                    <InputK  type='email' label="Email" name="email" placeholder="Digite seu email"  w='50%'    autoComplete={false}/>
                
                    <InputK  type='phone' label="Telefone" name="telefone" placeholder="Telefone" w='50%'   autoComplete={false}/>
                </Flex>
                <Flex w={'full'} gap='20px'>
                    <InputKCopy   loaded={load} focus={false}   label="Senha de Acesso" initial={{senha1:senha}}   name="senha1"   autoComplete={false}/>  
                       
                </Flex>  
                
                <ButtonKalyst w='full'>
                    Registrar Cliente
                </ButtonKalyst>  
            </SendForm>
            )
        }
        
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}