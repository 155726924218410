import { FaMehBlank, FaUsers } from "react-icons/fa";
import { LuArrowLeftRight, LuBanknote, LuBarChart2, LuBoxes, LuDollarSign, LuFileText, LuLink, LuLink2, LuListChecks, LuPackage, LuPanelLeft, LuPercent, LuStore, LuUploadCloud, LuUser, LuUser2, LuUserCog2, LuUserPlus, LuUsers, LuUsers2, LuWrench } from "react-icons/lu";
 
import { PagePainelLogin } from "../../pages/painel/auth/PageLogin";
import { PagePainelLost } from "../../pages/painel/auth/PageLost";  


import { PagePainelDashboard } from "../../pages/painel/dashboard/PageDashboard";
import { PagePainelDocument } from "../../pages/painel/document/PageDocument";

import { PagePainelClient } from "../../pages/painel/client/PageClient"; 
import { PagePainelReports } from "../../pages/painel/reports/PageReports"; 
import { fase } from "../../components/session/SessionKalyst"; 
import { PageAPI } from "../../pages/painel/api/PageAPI";
import { PageWebhooks } from "../../pages/painel/webhooks/PageWebhooks"; 
import { RiBankLine, RiExchange2Line } from "react-icons/ri";
import { PagePainelTransaction } from "../../pages/painel/transaction/PageTransaction";
import { PagePainelWithdraw } from "../../pages/painel/transaction/PageWithdraw";

import { GrTransaction } from "react-icons/gr";
import { FaCartFlatbed } from "react-icons/fa6";
import { PagePainelMarketplaces } from "../../pages/painel/marketplaces/PagePainelMarketplaces";

let painelRoutes = null;
export function getPainelRoutes(){
    return painelRoutes;
};
export function getMenuRoutes(login=false,acessos={}){
    let sendM= [];
    let subs = {}; 
    painelRoutes.forEach(rota => {
        if(login ){   
         
            if(rota.login  && (rota.fase == null|| rota.fase == fase)){
                if(rota.sub == null){
                    sendM.push({title:rota.title,icon:rota.icon,link:rota.url,hidden:rota.menu,tema:rota.tema});
                }else{
                    if(typeof subs[rota.sub.title] == 'undefined'){
                        subs[rota.sub.title] = {title:rota.sub.title,icon:rota.sub.icon,sub:[],hidden:false};
                        painelRoutes.forEach(rotaS => {
                            if(rotaS.sub!==null && rota.sub.title == rotaS.sub.title){
                                if(rotaS.menu){
                                    subs[rota.sub.title].hidden=true;
                                }
                                subs[rota.sub.title].sub.push({title:rotaS.title,icon:rotaS.icon,link:rotaS.url,hidden:rotaS.menu,tema:rotaS.tema})
                            }
                        })
                        sendM.push(subs[rota.sub.title]);
                    }
                    
                    
                }
                
            }
        }else{
            if(!rota.login && rota.menu){
                sendM.push(rota);
            }
        }
    });
    return sendM;
};
const newRoutePainel = function({title='',icon=null,description='',url = '',tema=false,layout='painel',login=false,page, session='sessionPainel',redirect='',menu=false, sub=null,acesso=0,fase:faseI=null}){
    painelRoutes.push({
        title,
        icon,
        description,
        layout,
        login,
        page,
        url,
        session,
        redirect,
        menu,
        sub,
        fase:faseI,
        tema,
        acesso
    })
}
const controller = '';
export function loadPainelRoutes(){
    if(painelRoutes == null){
        painelRoutes = [];
        
        newRoutePainel({ title       :   "Login",       url:controller+"",             page        : PagePainelLogin,     tema:false,   login       : false, redirect    : '/dashboard' });
        newRoutePainel({ title       :   "Login",       url:controller+"/login",       page        : PagePainelLogin,     tema:false,    login       : false, redirect    : '/dashboard' });
        
        newRoutePainel({ title       :   "Recuperação de conta",  url:controller+"/lost",        page        : PagePainelLost,          login: false, redirect    : '/dashboard' });
       
        
        newRoutePainel({ title       :   "Análise",         icon:LuBarChart2,       url:controller+"/dashboard",    page:   PagePainelDashboard,        tema:true,  login : true , menu:true  });
        newRoutePainel({ title       :   "Documentos",      icon:LuUploadCloud,     url:controller+"/document",     page:   PagePainelDocument,         tema:true,  fase: 1,  login : true , menu:true });
 
        // newRoutePainel({ title       :   "Transações",      icon: GrTransaction,    url:controller+"/transaction",  page:   PagePainelTransaction,      tema:true,  fase: 2,  login : true , menu:true });
        newRoutePainel({ title       :   "Pedidos",         icon: LuPackage,        url:controller+"/reports",      page:   PagePainelReports,          tema:true,  fase: 2,  login : true , menu:true }); 
        newRoutePainel({ title       :   "Marketplaces",    icon: LuUsers,          url:controller+"/marketplaces", page:   PagePainelMarketplaces,           tema:true,  fase: 2,  login : true , menu:true });
        // newRoutePainel({ title       :   "BasS",            icon: RiBankLine,       url:controller+"/withdraw",     page:   PagePainelWithdraw,         tema:true,  fase: 2,  login : true , menu:true });
        
        // newRoutePainel({ title       :   "Pré-chargeback",  url:controller+"/alerts",       page:   PagePainelReports,         tema:true,  fase: 2,  login : true , menu:true,     sub:{ title       :   "Chargeback",icon: RiExchange2Line} }); 
        // newRoutePainel({ title       :   "Estornos",        url:controller+"/refound",      page:   PagePainelReports,         tema:true,  fase: 2,  login : true , menu:true,     sub:{ title       :   "Chargeback",icon: RiExchange2Line} }); 
        // newRoutePainel({ title       :   "Chargeback",      url:controller+"/chageback",    page:   PagePainelReports,         tema:true,  fase: 2,  login : true , menu:true ,    sub:{ title       :   "Chargeback",icon: RiExchange2Line} }); 
        
        newRoutePainel({ title       :   "Credenciais de API",      url:controller+"/api",          page:   PageAPI,    tema:true,  fase: 2,  login : true , menu:true  ,     sub:{ title       :   "Integrações", icon: LuBoxes}});
        newRoutePainel({ title       :   "Webhooks",                url:controller+"/webhooks",     page:   PageWebhooks,    tema:true,  fase: 2,  login : true , menu:true  ,     sub:{ title       :   "Integrações"}});
       
    } 
    return painelRoutes;
}