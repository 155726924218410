import { io }   from "socket.io-client"; 
import md5 from 'md5'; 
import { config } from "../../theme/PageConfig";
export let web = {
    session     :   md5('kalystAdmin'), 
    socket      :   null,
    conectado   :   false,
    token       :   null 

}
export let user = null; 
export let fase = 0; 
export let GP = null;
export let srvTime = 0; 
export let saldos = {};
export const setSession = function(send){   
    localStorage.setItem(web.session,send.token); 
    web.token       =   send.token; 
    user            =   send.dados;  
    if(user.sysDoc == null){
        fase = 1; 
    }else if(user.sysDoc == 'S'){
        if(user.stores>0){
            fase = 3;
        }else{
            fase = 2;
        } 
    }  
}  
export const conectarServer = function(callback){ 
    if(!web.conectado){
        web.token     =   localStorage.getItem(web.session);  
      
        if(typeof web.token == 'undefined'|| web.token == '' || web.token == null){
            user = {};
        }  
        web.socket    =  new io("wss://"+config.prod+':5100',{  query: {  session: web.token , origin:  config.web} });
        let interV = null;  
        web.socket.on("connect", (socket) => {   
            GP={}; 
            web.conectado =true;
            clearInterval(interV);
            interV =  setInterval(()=>{   
                try {  web.socket.emit('autologin', web.token);  } catch (error) { }
                try {  web.socket.emit('dadosGeral' );           } catch (error) { } 
             },1000) 
        });  
        web.socket.on("timeServer", (time) => {  
            srvTime = time;
        });
        web.socket.on("autologin", (d) => {     
            setSession(d);
        });  
        web.socket.on("dadosGeralRemover", (d) => {   
            Object.keys(d).forEach((tab)=>{
                if(typeof GP[tab]  !== 'undefined'){ 
                    d[tab].forEach((id)=>{  
                       delete GP[tab].dados[id];
                    })
                } 
                GP[tab].up = new Date().getTime();
            })   
        }); 
        web.socket.on("disconnect", () => {  
            clearInterval(interV);  
            web.conectado =false;
        }); 
    }
    
} 
const tempCall = {};
const tempCampo = {};
export const sendFormSocket=({campo='sendForm',form,modulo,cmd,callback})=>{   
    if(web.socket!== null){
        var temp = (modulo+cmd+new Date().getTime());
        tempCall[temp] = callback;
        if(typeof tempCampo[campo] == 'undefined'){
            tempCampo[campo] = true; 
            web.socket.on(campo, function(d){    
                try {
                    tempCall[d.temp](d.result)
                    delete tempCall[d.temp]
                } catch (error) {
                    // console.log(error.message);;
                } 
            });
        } 
        setTimeout(()=>{ try { delete tempCall[temp]; } catch (error) { } },10000)
        web.socket.emit(campo,{form,modulo,cmd,temp:temp}); 
    } 
}